<template>
  <div class="page-home">
    <page-header />
    <div>
      <!-- 显示轮播图占位 -->
      <div class="page-home-top d-none d-sm-flex">
        <img
          v-if="imgList && imgList.length > 0"
          :src="imgList?.[0]?.imageUrl + '?x-oss-process=image/resize,l_100'"
          class="w-100"
          style="opacity: 0"
        />
        <v-carousel
          cycle
          hide-delimiter-background
          :interval="delayTime"
          v-model="model"
        >
          <v-carousel-item
            :selected-class="item.linkUrl ? 'link' : ''"
            v-for="item in imgList"
            :key="item.id"
            @click="jumpFn(item.linkUrl)"
            cover
          >
            <img :src="item.imageUrl" contain class="w-100 h-100" />
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="page-home-top mobile-terminal d-flex d-sm-none">
        <img
          :src="imgList?.[0]?.imageMobUrl + '?x-oss-process=image/resize,l_100'"
          class="w-100"
          style="opacity: 0"
        />
        <v-carousel
          cycle
          hide-delimiter-background
          :interval="delayTime"
          v-model="model"
        >
          <v-carousel-item
            v-for="item in imgList"
            :key="item.id"
            @click="jumpFn(item.linkUrl)"
            cover
          >
            <img :src="item.imageMobUrl" contain class="w-100 h-100" />
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="page-home-cont store-container sp-container">
        <h2 class="page-home-cont-tit mt-16 mb-5 d-none d-sm-flex fs-24">
          Best Sellers
        </h2>
        <h2 class="page-home-cont-tit mt-8 mb-5 d-flex d-sm-none fs-20">
          Best Sellers
        </h2>
        <v-row class="best-sellers">
          <template v-for="(item, i) in products" :key="i">
            <v-col
              cols="6"
              xs="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              xxl="2"
              class="mt-2 pa0"
            >
              <shopmall-product-item-new
                :productInfo="item"
                is-index-best
                showTag
              />
            </v-col>
          </template>
        </v-row>
        <h2 class="page-home-cont-tit mt-16 mb-5 d-none d-sm-flex fs-24">
          New Arrivals
        </h2>
        <h2 class="page-home-cont-tit mt-8 mb-5 d-flex d-sm-none fs-20">
          New Arrivals
        </h2>
        <v-row class="mb-8 new-arrivals">
          <template v-for="(item, i) in newArrival" :key="i">
            <v-col
              cols="6"
              xs="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              xxl="2"
              class="mt-2 pa0"
            >
              <shopmall-product-item-new
                :productInfo="item"
                is-new-arrivals
                showTag
              />
            </v-col>
          </template>
        </v-row>
        <h2
          class="page-home-cont-tit mb-8 mt-8 d-none d-sm-flex fs-24"
          v-if="categories.length"
        >
          Top Categories
        </h2>
        <h2
          class="page-home-cont-tit mb-8 mt-0 d-flex d-sm-none fs-20"
          v-if="categories.length"
        >
          Top Categories
        </h2>
        <div
          class="home-panle d-none d-sm-flex categories"
          v-if="categories.length"
        >
          <div
            class="home-panle-item pa-3 mb-8"
            v-for="(item, i) in categories"
            :key="i"
          >
            <div class="top-ategories" @click="jumpCategory(item)">
              <img
                :src="item.imageUrl + '?x-oss-process=image/resize,l_600'"
                class="align-end w-100"
                cover
              />
            </div>
          </div>
        </div>
        <div class="home-panle home-panle-sm d-flex d-sm-none categories">
          <div
            class="home-panle-item pa-3 mb-8"
            v-for="(item, i) in categories"
            :key="i"
          >
            <div class="top-ategories" @click="jumpCategory(item)">
              <img
                :src="item.imageUrl + '?x-oss-process=image/resize,l_600'"
                class="align-end w-100"
                cover
              />
            </div>
          </div>
        </div>
        <!-- Special Pricing -->
        <template v-if="clearanceItems.length">
          <h2
            class="page-home-cont-tit mt-16 mb-5 d-none d-sm-flex fs-24 special-pricing"
            :style="{
              background: 'url(' + $multiPlatform.clearancebg + ') no-repeat',
              backgroundSize: 'cover'
            }"
          >
            <span>Special Offer</span>
            <nuxt-link
              v-if="activityInProgressInfo?.[0]?.activityId"
              :to="`/activity/clearance/${activityInProgressInfo?.[0]?.activityId}`"
              class="ml-5 underline"
              >view all</nuxt-link
            >
          </h2>
          <h2
            class="page-home-cont-tit mt-8 mb-5 d-flex d-sm-none fs-18 special-pricing"
            :style="{
              background: 'url(' + $multiPlatform.clearancebg + ') no-repeat',
              backgroundSize: 'cover'
            }"
          >
            <span>Special Offer</span>
            <nuxt-link
              v-if="activityInProgressInfo?.[0]?.activityId"
              :to="`/activity/clearance/${activityInProgressInfo?.[0]?.activityId}`"
              class="ml-5 underline"
              >view all</nuxt-link
            >
          </h2>
        </template>
        <v-row class="mb-8">
          <template v-for="(item, i) in clearanceItems" :key="i">
            <v-col
              cols="6"
              xs="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              xxl="2"
              class="mt-2 pa0"
            >
              <shopmall-product-item-new :productInfo="item" />
            </v-col>
          </template>
        </v-row>
      </div>
      <!-- Order Tracking -->
      <v-form
        :class="[
          'order-tracking-box',
          { 'order-rg-tracking-box': $multiPlatform.mall === 'RG' }
        ]"
        ref="trackOrderFormRef"
        validate-on="blur"
      >
        <div class="sp-container">
          <v-row class="d-none d-sm-flex order-tracking-web ma-0">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="3"
              lg="5"
              xl="5"
              xxl="5"
              class="d-none d-lg-flex align-center fs-60 pa-0"
            >
              <em>Order</em>&nbsp;
              <em class="tracking">Tracking</em>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="4"
              lg="5"
              xl="5"
              xxl="5"
              class="d-none d-lg-none d-md-flex align-center fs-32 pa-0"
            >
              <em>Order</em>&nbsp;
              <em class="tracking">Tracking</em>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="4"
              lg="5"
              xl="5"
              xxl="5"
              class="d-none d-lg-none d-md-none d-sm-flex align-center justify-center fs-36 pa-0"
            >
              <em>Order</em>&nbsp;
              <em class="tracking">Tracking</em>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="8"
              lg="7"
              xl="7"
              xxl="7"
              class="d-flex pa-0"
            >
              <v-text-field
                class="ma-4"
                v-model.trim="trackOrderForm.orderNo"
                type="text"
                density="compact"
                :single-line="false"
                :clearable="false"
                hide-details
                bg-color="#fff"
                base-color="#fff"
                placeholder="* Order/PO No."
                variant="outlined"
              ></v-text-field>
              <v-text-field
                :class="['ma-4', { bor: !isMail }]"
                v-model.trim="trackOrderForm.email"
                density="compact"
                :rules="trackOrderFormRules.email"
                :single-line="false"
                :clearable="false"
                hide-details
                bg-color="#fff"
                base-color="#fff"
                placeholder="* Email Address"
                variant="outlined"
                v-if="!userStore?.userInfo?.userId"
              ></v-text-field>
              <v-btn
                class="mt-3"
                :disabled="!trackOrderBtnStyle"
                variant="outlined"
                flat
                @click="trackMyOrder"
                >Track My Order</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="d-flex d-sm-none order-tracking-mobile">
            <v-col cols="12" class="d-flex align-center justify-center pa-0">
              <em>Order</em>&nbsp;
              <em class="tracking">Tracking</em>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-text-field
                class="ma-4"
                v-model.trim="trackOrderForm.orderNo"
                type="text"
                density="compact"
                :single-line="false"
                :clearable="false"
                hide-details
                bg-color="#fff"
                base-color="#fff"
                placeholder="* Order/PO No."
                variant="outlined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0" v-if="!userStore?.userInfo?.userId">
              <v-text-field
                :class="['ma-4', { bor: !isMail }]"
                v-model.trim="trackOrderForm.email"
                density="compact"
                :rules="trackOrderFormRules.email"
                :single-line="false"
                :clearable="false"
                hide-details
                bg-color="#fff"
                base-color="#fff"
                placeholder="* Email Address"
                variant="outlined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-btn
                class="mt-4"
                :disabled="!trackOrderBtnStyle"
                variant="outlined"
                flat
                @click="trackMyOrder"
                >Track My Order</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-form>
      <!-- 评价展示 -->
      <div class="evaluate-box pt-5 pb-5" v-if="evaluateList.length">
        <div class="sp-container">
          <h3 class="fs-36 d-none d-sm-block text-center">
            What our customers are saying
          </h3>
          <h3 class="fs-20 d-block d-sm-none text-center">
            What our customers are saying
          </h3>
          <div>
            <shopmall-swiper :dataList="evaluateList"></shopmall-swiper>
          </div>
        </div>
      </div>
      <img :src="$multiPlatform.bootomIcon" style="width: 100%" />
    </div>
    <!-- 更新密码弹窗 -->
    <shopmall-dialog
      ref="pwdDialog"
      :show-confirm="false"
      :show-title="false"
      :max-width="500"
    >
      <div>
        <shopmall-updatepassword
          :elevation="0"
          dialog
          @skipFn="skip"
          :updatePasswordType="updatePasswordType"
        ></shopmall-updatepassword>
      </div>
    </shopmall-dialog>

    <page-footer></page-footer>
  </div>
</template>
<script setup scoped>
import { isEmail } from "kits";
import {
  productInteractor,
  systemInteractor,
  authInteractor,
  promotionsInteractor,
  customerInteractor
} from "~/core";
import {
  fetchCarouselImages,
  fetchBestSellers,
  fetchNewArrivals,
  fetchCategories,
  fetchActivityInProgress,
  fetchClearanceItems,
  fetchEvaluateList
} from "~/core/server";
import { useProductStore, useUserStore } from "~/stores";
const {
  $multiPlatform,
  $validate,
  $Utils,
  $Buryingpoint,
  $Confirm,
  $BuryIntersectionObserver
} = useNuxtApp();
const router = useRouter();
const category = useCategory();
const config = useRuntimeConfig();
const userStore = useUserStore();
const primaryColor = config.public.primaryColor;
const companyName = config.public.companyName;
// seo
useHead({
  title: `${companyName} - Customized design and on-demand printing`,
  meta: [
    {
      name: "description",
      content: `At ${companyName}, we provide an extensive selection of custom-made promotional products known for their highly competitive pricing and industry-leading turnaround times. Explore our product catalog, which includes notebooks, trade show items, gadgets, giveaways, and promotional merchandise.`
    },
    {
      name: "keywords",
      content: `${companyName}, Rivers, Promo, promotional, customized, customizable, trade show, promo items, product sourcing, giveaway, office supplies, toys, keychains, stress balls, bags, mugs, cups, accessories, fitness, sweatbands, hats, caps, phone accessories, USB, car, auto accessories, logo, desktop, tools, gadgets, party supplies, pet supplies, household items, Power Banks, Chargers, Smart Phone, PPE, Personal Protective Equipment, Protective Gear, Disposable Gloves, Alcohol Wipes, Face Mask`
    }
  ]
});
const updatePasswordType = ref(1); //1是商城老客户2是商城后台新增用户展示样式不同
const productStore = useProductStore();
const model = ref(0);
const imgList = ref([]);
const trackOrderFormRules = ref({
  email: [v => $validate.Email(v, false)]
});
const isMail = ref(true);
const trackOrderFormRef = ref(null);
const activityInProgressInfo = ref([]);
const trackOrderForm = ref({
  orderNo: "",
  email: ""
});
const products = ref([
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  }
]);
const newArrival = ref([
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  }
]);
const categories = ref([
  {
    imageUrl: ""
  }
]);
const clearanceItems = ref([
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  },
  {
    imgMainUrl: "",
    nameEn: "",
    price: ""
  }
]); //清仓商品
//评价
const evaluateList = ref([]);
const trackOrderBtnStyle = computed(() => {
  if (userStore?.userInfo?.userId) {
    return trackOrderForm.value.orderNo;
  } else {
    return trackOrderForm.value.orderNo && trackOrderForm.value.email;
  }
});
const trackMyOrder = async () => {
  if (!userStore?.userInfo?.userId)
    isMail.value = isEmail(trackOrderForm.value.email);
  if (!isMail.value) return;
  router.push({
    path: "/my-account/order-tracking",
    query: {
      orderCode: trackOrderForm.value.orderNo,
      email: btoa(trackOrderForm.value.email)
    }
  });
};
// 分类跳转
const jumpCategory = item => {
  productStore.setAccessories({
    id: item.categoryId,
    nameEn: item.categoryNameEn
  });
  category.setCategorysInfo(item);

  navigateTo(
    `/category/${$Utils.replaceSpacesWithDash(item.categoryNameEn, false)}`
  );
};
const delayTime = ref(5000);
definePageMeta({
  layout: false,
  middleware: ["auth"]
});
// 获取轮播图数据
const carouselData = await fetchCarouselImages();
const carouselImagesState = useState("carouselImages");
if (carouselImagesState.value) {
  imgList.value = carouselImagesState.value;
} else if (carouselData && carouselData?.code === "000000") {
  useState("carouselImages", () => carouselData.data || []);
  imgList.value = carouselData?.data ?? [];
} else {
  imgList.value = [];
}
// 获取Best Sellers
const bestSellersData = await fetchBestSellers();
const bestSellersState = useState("bestSellers");
if (bestSellersState.value) {
  products.value = bestSellersState.value;
} else if (bestSellersData && bestSellersData?.code === "000000") {
  const formattedData =
    bestSellersData?.data?.map(item => ({
      ...item,
      price: ""
    })) ?? [];
  useState("bestSellers", () => formattedData);
  products.value = formattedData;
} else {
  products.value = [];
}

// 获取New Arrivals
const newArrivalsData = await fetchNewArrivals();
const newArrivalslDataListData = useState("newArrivalslDataStateData");
if (newArrivalslDataListData.value) {
  newArrival.value = newArrivalslDataListData.value;
} else if (newArrivalsData && newArrivalsData?.code === "000000") {
  const formattedData =
    newArrivalsData?.data?.map(item => ({
      ...item,
      price: ""
    })) ?? [];
  useState("newArrivalslDataStateData", () => formattedData);
  newArrival.value = formattedData;
} else {
  newArrival.value = [];
}

// 获取类目
const categoriesData = await fetchCategories();
const categoriesStateData = useState("categoriesState");
if (categoriesStateData.value) {
  categories.value = categoriesStateData.value;
} else if (categoriesData && categoriesData?.code === "000000") {
  useState("categoriesState", () => categoriesData.data || []);
  categories.value = categoriesData?.data ?? [];
} else {
  categories.value = [];
}

// 商城-查询进行中的清仓活动
const activityInProgressData = await fetchActivityInProgress();
const activityInProgressStateData = useState("activityInProgressUseState");
if (activityInProgressStateData.value) {
  activityInProgressInfo.value = activityInProgressStateData.value;
} else if (
  activityInProgressData &&
  activityInProgressData?.code === "000000"
) {
  useState(
    "activityInProgressUseState",
    () => activityInProgressData.data || []
  );
  activityInProgressInfo.value = activityInProgressData?.data ?? [];
} else {
  activityInProgressInfo.value = [];
}

// 获取清仓商品
const clearanceItemsData = await fetchClearanceItems({
  current: 1,
  size: 6,
  activityId: activityInProgressInfo?.value?.[0]?.activityId
});
const clearanceItemsStateData = useState("clearanceItemsUseState");
if (clearanceItemsStateData.value) {
  clearanceItems.value = clearanceItemsStateData.value;
} else if (clearanceItemsData && clearanceItemsData?.code === "000000") {
  const formattedData =
    clearanceItemsData?.data?.records?.map(item => ({
      ...item,
      price: ""
    })) ?? [];
  useState("clearanceItemsUseState", () => formattedData);
  clearanceItems.value = formattedData;
} else {
  clearanceItems.value = [];
}

// 获取评价
const evaluateListData = await fetchEvaluateList();
const evaluateListStateData = useState("evaluateState");
if (evaluateListStateData.value) {
  evaluateList.value = evaluateListStateData.value;
} else if (evaluateListData && evaluateListData?.code === "000000") {
  useState("evaluateState", () => evaluateListData?.data || []);
  evaluateList.value = evaluateListData?.data ?? [];
} else {
  evaluateList.value = [];
}

// 获取清仓商品
const getClearanceItems = async () => {
  if (!activityInProgressInfo?.value?.[0]?.activityId) return;
  const [res, err] = await promotionsInteractor.activityProductPageApi({
    current: 1,
    size: 6,
    activityId: activityInProgressInfo.value[0].activityId
  });
  clearanceItems.value = res?.records ?? [];
};

const showLoginStatusChange = ref(true);
const onBroadCastChannelMessage = () => {
  try {
    const BC = new BroadcastChannel("LOGIN");
    BC.onmessage = () => {
      if (showLoginStatusChange.value) {
        $Confirm({
          title:
            "<p>You have switched to another account.</p><p> Please refresh to continue.</p>",
          showCancelButton: false
        }).then(result => {
          if (result?.isConfirmed) {
            window.location.reload();
          }
        });
      }
    };
  } catch (error) {
    if (typeof BroadcastChannel === "undefined") {
      const browserInfo = $Utils.getBrowserInfo();
      throw new Error(`BroadcastChannel API不兼容: ${browserInfo}`);
    } else {
      throw error;
    }
  }
};
const getList = async () => {
  if (!productInteractor) return;
  const [res, err] = await productInteractor.listBestSellersApi();
  if (res) {
    products.value = res;
  } else {
    products.value = [];
  }
};

// newArrival产品列表
const getNewArrival = async () => {
  if (!productInteractor) return;
  const [res, err] = await productInteractor.listNewArrivalApi();
  if (res) {
    newArrival.value = res;
  } else {
    newArrival.value = [];
  }
};
// 点击轮播跳转
const jumpFn = url => {
  if (!url) return;
  // 埋点轮播图点击事件
  $Buryingpoint.bannerClick(url);
  window.open(`${url}?t=ba`, "_blank");
};

const pwdDialog = ref(null);

const auth = useAuth();
const cart = useCart();
const oneTimeLogin = async () => {
  let { email, param, type } = $Utils.getQueryParams(window.location.href);
  if (type === "onetimelogin") {
    let isSameUser = auth.isSameLoginUser({ email });
    // 判断是否登录
    let isLoggedIn = await auth.isLoggedIn();
    if (param) {
      const [userInfo, err] = await authInteractor.emailLoginMallApi(param);
      let [checkRes, checkErr] = await authInteractor.checkEmailApi(email);

      if (userInfo && userInfo?.type === 0) {
        // 未失效场景
        // 正常登录
        userStore.setUserInfo({
          ...userInfo
        });
        userStore.setAccessToken(userInfo.accessToken);
        userStore.setRefreshToken(userInfo.refreshToken);
        // 合并购物车数据
        cart.initCartData();
        // 获取用户详情
        auth.setUserInfo();

        // 显示登录弹窗
        if (checkRes?.type == 3) {
          //未设置密码在显示设置密码弹窗
          pwdDialog.value && pwdDialog.value.show();
        }
        userStore.setOneTimeLoginEmail("");
        // 已登录的情况下并且不是相同用户
        if (!isSameUser && isLoggedIn) {
          if (typeof BroadcastChannel === "undefined") return;
          const BC = new BroadcastChannel("LOGIN");
          showLoginStatusChange.value = false;
          BC.postMessage("login-user-change");
        }
      } else if (userInfo && userInfo.type === 3) {
        // 判断是否相同账号
        if (!isSameUser) {
          // 不是相同账号则返回到重新登录页面
          // 链接失效，需要重新登录
          userStore.setOneTimeLoginEmail(email);
          // 跳转到登录页
          navigateTo("/account/signin");
        } else {
          // 相同账号
          if (isLoggedIn) {
            // 相同账号则直接显示登录弹窗
            if (checkRes.type == 3) {
              pwdDialog.value && pwdDialog.value.show();
            }
          } else {
            navigateTo("/account/signin");
          }
        }
      }
    }
  }
};

// 跳过更新密码
const skip = async () => {
  pwdDialog.value && pwdDialog.value.show();
  if (updatePasswordType.value === 1) {
    const [res, err] = await authInteractor.customerDetailApi(
      userStore.userInfo.customerId
    );
    if (res) {
      userStore.setUserInfo({
        status: res.status
      });
    }
    navigateTo("/");
  }
};
onMounted(() => {
  oneTimeLogin();
  getList();
  getNewArrival();
  onBroadCastChannelMessage();
  getClearanceItems();
  nextTick(() => {
    if (userStore?.userInfo?.type == 4 && userStore.updatePasswordBol) {
      updatePasswordType.value = 2;
      pwdDialog.value.show();
      if (userStore.updatePasswordBol) userStore.setUpdatePasswordBol();
    }
    try {
      // 轮播图曝光
      new $BuryIntersectionObserver(".v-window-item");
      // Best Sellers商品曝光
      new $BuryIntersectionObserver(".best-sellers .sp-product-item-wrapper");
      // newArrival产品曝光
      new $BuryIntersectionObserver(".new-arrivals .sp-product-item-wrapper");
    } catch (err) {
      console.log(`商品曝光失败原因${err}`);
    }
  });
});
</script>
<style lang="scss">
html {
  overflow: auto;
}
.page-home {
  width: 100%;
  position: relative;
  min-height: 100vh;
  height: 100%;
  background-color: white;
  --primary-color: v-bind(primaryColor);
  .underline {
    text-decoration: underline;
  }
  .top-ategories {
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.035);
    }
  }
  .home-panle {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
    &.categories {
      min-height: 400px;
    }
    .home-panle-item {
      width: 20%;
    }
    &.home-panle-sm {
      .home-panle-item {
        width: 50%;
      }
    }
  }
  .contac-us-icon {
    position: fixed;
    right: 25px;
    bottom: 140px;
    z-index: 999;
  }
  .page-home-top {
    position: relative;
    .v-carousel {
      height: 100% !important;
      position: absolute;
      z-index: 2;
      .v-carousel-item {
        &.link {
          cursor: pointer;
        }
      }
      .v-carousel__controls {
        .v-btn {
          .v-btn__content {
            .mdi-circle {
              color: #fff;
            }
          }
          .v-btn__overlay {
            background: var(--primary-color);
          }
          &.v-btn--active {
            .v-btn__content {
              .mdi-circle {
                color: var(--primary-color);
              }
            }
          }
        }
      }
    }
    &.mobile-terminal {
      .v-carousel {
        .v-window__controls {
          .v-btn {
            display: none;
          }
        }
        .v-btn {
          width: 10px;
          height: 10px;
          .v-btn__content {
            i {
              font-size: 8px;
            }
          }
        }
        .v-carousel__controls {
          height: 14px;
          .v-btn {
            width: 5px;
            height: 5px;
            margin: 0 2px;
            .v-btn__content {
              width: 5px;
              height: 5px;
              i {
                font-size: 5px;
              }
            }
          }
        }
      }
    }
    .page-home-top-panel {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: linear-gradient(
        to right,
        rgba(85, 85, 85, 0.6) 20%,
        rgba(85, 85, 85, 0.3) 40%,
        rgba(85, 85, 85, 0.1) 50%,
        rgba(85, 85, 85, 0) 100%
      );
      .page-home-top-panel-item {
        height: 100%;
      }
    }
  }
  .page-home-cont {
    .page-home-cont-tit {
      line-height: 24px;
      color: #1a1a1a;
      position: relative;
      &.special-pricing {
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 60px;
        line-height: 60px;
        font-size: 30px;
        color: #fff;
        text-align: center !important;
        margin-top: 0 !important;
        a {
          color: #fff;
          font-weight: normal;
          font-size: 20px;
        }
      }
    }
    .categories {
      .card-title {
        font-size: 28px;
        color: #fff;
        text-align: center;
        height: 82px;
        line-height: 82px;
        background-color: rgba(2, 167, 240, 0.6);
      }
    }
  }
  :deep(.min-height-carouse) {
    min-height: 356px;
  }
}
.v-skeleton-loader__image {
  height: 100% !important;
}
.order-tracking-box {
  padding: 12px;
  background-color: rgba(2, 167, 240, 1);
  .v-input {
    margin: 12px 12px 12px 0 !important;
    .v-input__control {
      .v-field__input {
        height: 58px !important;
      }
      .v-field__outline {
        display: none !important;
      }
    }
    .v-field__overlay {
      box-sizing: border-box;
      border: 1px solid #fff;
    }
    &.bor {
      .v-field__overlay {
        border-color: red;
        &::after {
          content: "Incorrect Email Address";
          position: absolute;
          top: 105%;
          color: #fff;
        }
      }
    }
  }
  .v-btn {
    height: 62px !important;
    color: #fff;
    opacity: 1;
    border-width: 2px;
    font-weight: 600;
    &.v-btn--disabled {
      border-color: #ccc;
      opacity: 1;
      background-color: #ccc;
    }
  }
  .order-tracking-web {
    padding: 10px 0;
    em {
      color: #fff;
      font-weight: bold;
      &.tracking {
        color: #016490;
      }
    }
  }
  .order-tracking-mobile {
    em {
      color: #fff;
      font-weight: bold;
      font-size: 40px;
      &.tracking {
        color: #016490;
      }
    }
    .v-btn {
      width: 100%;
      margin-bottom: 24px;
    }
    .v-input {
      margin: 10px 0 !important;
    }
  }
  &.order-rg-tracking-box {
    background-color: #b51642;
    .order-tracking-web {
      em {
        &.tracking {
          color: #8e0030;
        }
      }
    }
    .order-tracking-mobile {
      em {
        &.tracking {
          color: #8e0030;
        }
      }
    }
  }
}
.evaluate-box {
  background-color: rgba(242, 242, 242, 1);
  h3 {
    color: #016490;
  }
  .v-card--variant-elevated {
    box-shadow: none;
    .evaluate-rating {
      .v-rating__wrapper {
        margin: 0 6px;
      }
    }
    .evaluate-text {
      width: 100%;
      font-size: 16px;
      height: 88px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch; /* 确保在移动端流畅滚动 */
      touch-action: pan-y; /* 允许水平滑动，禁止垂直滑动 */
    }
    .evaluate-time {
      font-size: 14px;
    }
  }
}
</style>
